/* Create new list / update new list */
/* get list of lists */
/* delete list */
/* get individual list */
import axios from '@utils/axios';

import {
  ThunkAction,
  ThunkDispatch,
  List,
  LIST_VIEW_LOADED,
  LIST_VIEW_SET_CURRENT_SLUG
} from '../types';

export const loadListFromSlug =
  (listSlug: string): ThunkAction =>
  async (dispatch: ThunkDispatch) => {
    try {
      dispatch({ type: LIST_VIEW_SET_CURRENT_SLUG, currentSlug: listSlug });
      const requestStartedAt = new Date().getTime();
      const endPoint = `/api/get-list?slug=${listSlug}`;
      const { data } = await axios.get<{
        data: List;
      }>(endPoint);
      console.log(
        `${endPoint} - ${new Date().getTime() - requestStartedAt} ms`
      );

      dispatch({ type: LIST_VIEW_LOADED, list: data });
    } catch (err) {
      console.error(err);
    }
  };
