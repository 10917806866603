/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useState, createContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';

import theme, { darkTheme } from './theme/theme';

const ThemeContext = createContext<{
  isDarkMode: boolean;
  toggleDarkMode: () => void;
}>({
  isDarkMode: false,
  toggleDarkMode: () => {}
});

const ThemeContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const prefersDarkMode = window.matchMedia(
      '(prefers-color-scheme: dark)'
    ).matches;

    if (prefersDarkMode) {
      return prefersDarkMode;
    }

    // Fallback to cookie or system preferences
    const savedTheme = document.cookie.replace(
      /(?:(?:^|.*;\s*)theme\s*=\s*([^;]*).*$)|^.*$/,
      '$1'
    );

    // Prefer the initial theme from the server (if provided)
    if (
      typeof window !== 'undefined' &&
      window.__INITIAL_THEME__ &&
      !prefersDarkMode
    ) {
      return window.__INITIAL_THEME__ === 'dark';
    }

    return savedTheme === 'dark' || (!savedTheme && prefersDarkMode);
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleThemeChange = (e: MediaQueryListEvent) =>
      setIsDarkMode(e.matches);
    mediaQuery.addEventListener('change', handleThemeChange);
    return () => mediaQuery.removeEventListener('change', handleThemeChange);
  }, []);

  useEffect(() => {
    const isSecure = window?.location?.protocol === 'https:';
    const maxAge = 365 * 24 * 60 * 60; // 1 year
    const domain = isSecure ? '.jungle.deals' : 'localhost';

    // Fallback to cookie or system preferences
    const savedTheme = document.cookie.replace(
      /(?:(?:^|.*;\s*)theme\s*=\s*([^;]*).*$)|^.*$/,
      '$1'
    );

    const currentTheme = isDarkMode ? 'dark' : 'light';

    document.cookie = `theme=${currentTheme}; path=/; Max-Age=${maxAge}; SameSite=Lax; ${
      isSecure ? 'Secure' : ''
    }; domain=${domain}`;
    document.documentElement.setAttribute('data-theme', currentTheme);

    // only refresh if this type exists
    if (savedTheme && savedTheme !== currentTheme) {
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => setIsDarkMode((prev) => !prev);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      <ThemeProvider theme={isDarkMode ? darkTheme : theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
